export const countyLegislatures = [
  // "dutchess" is not included because we want both legislative and district offices to show up for Dutchess legislators
  "Nassau County Legislature",
  "Suffolk County Legislature",
  "Orange County Legislature",
  "Putnam County Legislature",
  "Ulster County Legislature",
  "Westchester County Board of Supervisors",
];

// These constants are used to fetch data from Airtable and store it on state in the app.
// The "long" government body strings are passed to the Airtable fetch request url in utilities/apiWrapper.js
// They must exactly match the corresponding view name in Airtable.
// The "short" government body strings must match the keys in the object passed to combineReducers in reducers/index.js
export const governmentBodies = {
  senate: {
    long: "Senate",
    short: "senate",
  },
  assembly: {
    long: "Assembly",
    short: "assembly",
  },
  nyc: {
    long: "NYC Council",
    short: "council",
  },
  nassau: {
    long: "Nassau County Legislature",
    short: "nassau",
  },
  suffolk: {
    long: "Suffolk County Legislature",
    short: "suffolk",
  },
  dutchess: {
    long: "Dutchess County Legislature",
    short: "dutchess",
  },
  orange: {
    long: "Orange County Legislature",
    short: "orange",
  },
  putnam: {
    long: "Putnam County Legislature",
    short: "putnam",
  },
  ulster: {
    long: "Ulster County Legislature",
    short: "ulster",
  },
  westchester: {
    long: "Westchester County Board of Supervisors",
    short: "westchester",
  },
  buffalo: {
    long: "Buffalo Common Council",
    short: "buffalo",
  },
  erie: {
    long: "Erie County Legislature",
    short: "erie",
  },
  rochester: {
    long: "Rochester City Council",
    short: "rochester",
  },
  monroe: {
    long: "Monroe County Legislature",
    short: "monroe",
  },
};

export const defaultColSize = { sm: [12, 12], md: [4, 4], lg: [4, 4] };

// The values for race and party set here are used in the Legend labels
export const legendCategories = [
  { race: "Arab/Asian", party: "Democratic", id: "arab-asian-democratic" },
  { race: "Black", party: "Democratic", id: "black-democratic" },
  { race: "Latino/a", party: "Democratic", id: "latinx-democratic" },
  { race: "White", party: "Democratic", id: "white-democratic" },
  { race: "Arab/Asian", party: "Republican", id: "arab-asian-republican" },
  { race: "Black", party: "Republican", id: "black-republican" },
  { race: "Latino/a", party: "Republican", id: "latinx-republican" },
  { race: "White", party: "Republican", id: "white-republican" },
  { race: "White", party: "Conservative", id: "white-conservative" },
  { race: "White", party: "Independent", id: "independent" },
  { race: "", party: "Undecided", id: "undecided" },
  { race: "Unknown", party: "Unknown", id: "race-unknown" },
  { race: "Unknown", party: "Democratic", id: "race-unknown" },
  { race: "Unknown", party: "Republican", id: "race-unknown" },
];

export const genderOptions = [
  { value: "Female", label: "Female" },
  { value: "Nonbinary", label: "Nonbinary" },
  { value: "Male", label: "Male" },
  { value: "Transgender", label: "Transgender" },
  { value: "Unknown", label: "Unknown" },
];
export const raceOptions = [
  { value: "Arab/Asian", label: "Arab/Asian" },
  { value: "Black", label: "Black" },
  { value: "Latino/a", label: "Latino/a" },
  { value: "White", label: "White" },
  { value: "Unknown", label: "Unknown" },
];
export const regionOptions = [
  { value: "Capital Region", label: "Capital Region" },
  { value: "Central New York", label: "Central New York" },
  { value: "Finger Lakes", label: "Finger Lakes" },
  { value: "Long Island", label: "Long Island" },
  { value: "Mid-Hudson", label: "Mid-Hudson" },
  { value: "Mohawk Valley", label: "Mohawk Valley" },
  { value: "New York City", label: "New York City" },
  { value: "North Country", label: "North Country" },
  { value: "Southern Tier", label: "Southern Tier" },
  { value: "Western New York", label: "Western New York" },
];

export const fieldNames = {
  RACE: "Race",
  LEGISLATIVE_CONFERENCE: "Legislative Conference",
  COMMITTEE_ASSIGNMENTS_AND_CAUCUSES:
    "Lookup Committee Assignments and Caucuses",
  TITLE: "Title",
  LEADERSHIP_TITLE: "Leadership Title",
  CHAIRPERSONSHIP: "Lookup Chairpersonship",
  IN_OFFICE_SINCE: "In Office Since (Year)",
  SEXUAL_ORIENTATION: "Sexual Orientation",
  GENDER: "Gender",
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram Handle",
  TWITTER: "Twitter Handle",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  FULL_NAME_TITLE: "Full Name, Title",
  PARTY: "Party",
  DISTRICT_REPRESENTED: "District Represented",
  STAFF_RESPONSIBILITY: "Staff Responsibility",
  IMAGE: "Image",
  RANKER_FOR: "Lookup Ranker For",
  VICE_CHAIRPERSONSHIP: "Lookup Vice Chairpersonship",
  DISTRICT_OFFICE_ADDRESS: "Address 1 / District Office",
  CAPITOL_OFFICE_ADDRESS: "Address 2 / Capitol or Legislative Office",
  OTHER_OFFICE_ADDRESS: "Address 3 / Other",
  DISTRICT_OFFICE_PHONE: "Phone 1 / District Office",
  CAPITOL_OFFICE_PHONE: "Phone 2 / Capitol or Legislative Office",
  OTHER_OFFICE_PHONE: "Phone 3 / Other",
  DISTRICT_OFFICE_FAX: "Fax 1 / District Office",
  CAPITOL_OFFICE_FAX: "Fax 2 / Capitol or Legislative Office",
  EMAIL: "Email",
  EMAIL_2: "Email 2",
  WEBSITE: "Website",
  BOROUGH: "Lookup Borough",
  PUBLISH: "Publish",
  REGION: "Lookup Region",
  MAP_IMAGE: "Lookup Map Image",
  VOTING_AGE_POP: "Voting age pop",
  REGISTERED_VOTERS: "Registered voters",
  POC_REGISTRATION: "POC registration",
  TURNOUT_2018: "Turnout 2018",
  TURNOUT_2019: "Turnout 2019",
  TURNOUT_2020: "Turnout 2020",
  TURNOUT_2021: "Turnout 2021",
  PREVIEW: "Preview",
  NEIGHBORHOODS: "Neighborhoods, Cities, and Counties",
};

export const mapDataUrls = {
  nycMapUrl:
    "https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/NYC_City_Council_Districts/FeatureServer/0/query?where=1=1&outFields=*&outSR=4326&f=pgeojson",
  assemblyMapUrl:
    "https://gisservices.its.ny.gov/arcgis/rest/services/NYSLayersMap/MapServer/1/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryPolygon&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson",
  senateMapUrl:
    "https://gisservices.its.ny.gov/arcgis/rest/services/NYSLayersMap/MapServer/6/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryPolygon&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson",
  nassauMapUrl:
    "https://services1.arcgis.com/rsOafHhKLHpnpr4t/arcgis/rest/services/Nassau_Legislative_Districts_2020/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryPolygon&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields=*&returnGeometry=true&returnCentroid=false&returnEnvelope=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=",
  suffolkMapUrl:
    "https://gis.suffolkcountyny.gov/server/rest/services/LocalGovernmentSQLData/LegislativeDistrict/MapServer/0/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryPolygon&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson",
};
